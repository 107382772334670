import React, { Component } from 'react';
import { Link } from 'gatsby';
import { SliceZone } from '@prismicio/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Seo from './Seo';
import { components } from '../slices/index';

import '../styles/components/_public-projects.scss';

class PublicProjectContent extends Component {
  render() {
    return (
      <>
      <Seo title={this.props.publicProject.project_name.text} />
      <Container as='section' className='project__container'>
        <Row>
          <Col>
            <h1 className='project__title'>{this.props.publicProject.project_name.text}</h1>
            <p className='project__description'>{this.props.publicProject.description.text}</p>
            <Row className='project__details'>
              {this.props.publicProject.external_link.url === null ?
                <>
                  <Col sm={4} xs={6}>
                    <h4 className='project__client--title'>CLIENT</h4>
                    <p className='project__client'>{this.props.publicProject.company.text}</p>
                  </Col>
                  <Col sm={4} xs={6}>
                    <h4 className='project__role--title'>ROLE</h4>
                    <p className='project__role' dangerouslySetInnerHTML={{ __html: `${this.props.publicProject.role.html}` }} />
                  </Col>
                </>
              :
                <>
                  <Col md={4} sm={6}>
                    <h4 className='project__client--title'>CLIENT</h4>
                  <p className='project__client'>{this.props.publicProject.company.text}</p>
                  </Col>
                  <Col md={4} sm={6}>
                    <h4 className='project__role--title'>ROLE</h4>
                    <p className='project__role' dangerouslySetInnerHTML={{ __html: `${this.props.publicProject.role.html}` }} />
                  </Col>
                  <Col>
                    <a href={`${this.props.publicProject.external_link.url}`} target='_blank' rel='noreferrer'><div className='project__cta'>{this.props.publicProject.link_text.text}</div></a>
                  </Col>
                </>
              }
            </Row>
            <SliceZone slices={this.props.publicProject.body} components={components} />
            <Col xs={12}>
              <Link to='/projects'><div className='project__cta b-10'><FontAwesomeIcon icon={ faArrowLeftLong } className='project__arrow-icon' />&nbsp;BACK TO PROJECTS</div></Link>
            </Col>
          </Col>
        </Row>
      </Container>
      </>
    )
  }
}

export default PublicProjectContent