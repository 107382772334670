import React from 'react';
import { graphql } from 'gatsby';
import { motion, useIsPresent } from 'framer-motion';

import Layout from '../components/Layout';
import PublicProjectContent from '../components/PublicProject';

const Project = ({ data }) => {
  const isPresent = useIsPresent();
  const pubProject = data.prismicProject;
  return (
    <Layout pageId={pubProject.uid}>
      <PublicProjectContent publicProject={pubProject.data} />
      <motion.div
        initial={{ scaleY: 1 }}
        animate={{ scaleY: 0, transition: { duration: 0.75, ease: [0.16, 1, 0.3, 1] } }}
        exit={{ scaleY: 1, transition: { duration: 0.75, ease: [0.7, 0, 0.84, 0] } }}
        style={{ originY: isPresent ? 0 : 1 }}
        className='screen-page-transition'
      />
    </Layout>
  )
}

export default Project

export const publicQuery = graphql`
query projectQuery($uid: String!) {
  prismicProject(uid: {eq: $uid}) {
    uid
    data {
      project_name {
        text
      }
      description {
        text
      }
      company {
        text
      }
      role {
        html
      }
      external_link {
        url
      }
      link_text {
        text
      }
      body {
        ... on PrismicProjectDataBodyVideoLinkWithDescription {
          slice_type
          primary {
            title {
              text
            }
            description1 {
              text
            }
          }
          items {
            css_class {
              text
            }
            url {
              url
            }
          }
        }
        ... on PrismicProjectDataBodyVideoLinkWithoutDescription {
          slice_type
          items {
            css_class {
              text
            }
            url {
              url
            }
          }
        }
        ... on PrismicProjectDataBodyGalleryWithDescription {
          slice_type
          primary {
            title {
              text
            }
            description1 {
              text
            }
          }
          items {
            css_class {
              text
            }
            image {
              alt
              gatsbyImageData(outputPixelDensities: 1.5)
            }
          }
        }
        ... on PrismicProjectDataBodyGallery {
          slice_type
          items {
            css_class {
              text
            }
            screenshot {
              alt
              gatsbyImageData(outputPixelDensities: 1.5)
            }
          }
        }
      }
    }
  }
}`