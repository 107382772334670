import React, { useEffect, useRef, useState } from "react";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Col from "react-bootstrap/Col";

const HiddenPrivateThumb = ({ handleKeyboardVisible, hiddenImage }) => {
  const ref = useRef();
  const [hovered, setHovered] = useState(false);
  const [displayedText, setDisplayedText] = useState({
    name: "MAECENASBELEIFEND",
    company: "CONSECTETURXADIPISCINGOELIT",
  });

  useEffect(() => {
    ref.current.addEventListener("mousemove", (e) => {
      const rect = e.target.getBoundingClientRect();
      const imageCenterX = rect.left + rect.width / 2;
      const imageCenterY = rect.top + rect.height / 2;
      const clientX = e.clientX;
      const clientY = e.clientY;
      const xCalc = (clientX - imageCenterX) * 0.0000005;
      const yCalc = (clientY - imageCenterY) * 0.0000005;

      e.currentTarget.style.setProperty(
        "transform",
        `matrix3d(
          1,0,0,${xCalc},
          0,1,0,${yCalc},
          0,0,1,0,
          0,0,0,1
        )`
      );
    });

    ref.current.addEventListener("mouseout", (e) => {
      e.currentTarget.style.setProperty(
        "transform",
        `matrix3d(
          1,0,0,0,
          0,1,0,0,
          0,0,1,0,
          0,0,0,1
        )`
      );
    });
  }, []);

  const handleMouseEnter = () => {
    setHovered(true);
    startScrambling();
  };

  const handleMouseLeave = () => {
    setHovered(false);
    startRescrambling();
  };

  const startScrambling = () => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@#$%&*";
    let iterations = 0;

    const scramble = (originalText) =>
      originalText
        .split("")
        .map((char, i) => {
          if (i < iterations) return char;
          return chars[Math.floor(Math.random() * chars.length)];
        })
        .join("");

    const interval = setInterval(() => {
      setDisplayedText((prev) => ({
        name: scramble("PASSCODE REQUIRED"),
        company: scramble("CLICK TO OPEN THE KEYPAD"),
      }));

      if (iterations >= "PASSCODE REQUIRED".length) {
        clearInterval(interval);
        setDisplayedText({ name: "PASSCODE REQUIRED", company: "CLICK TO OPEN THE KEYPAD" });
      }

      iterations += 1 / 3; // Adjust resolution speed
    }, 12);
  };

  const startRescrambling = () => {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let iterations = 0;

    const scramble = (length) =>
      Array.from({ length }, () =>
        chars[Math.floor(Math.random() * chars.length)]
      ).join("");

    const interval = setInterval(() => {
      setDisplayedText({
        name: scramble(17),
        company: scramble(24),
      });

      if (iterations >= 10) {
        clearInterval(interval);
        resetToRescrambled();
      }

      iterations += 1 / 3; // Adjust resolution speed
    }, 12);
  };

  const resetToRescrambled = () => {
    setDisplayedText({
      name: "MAECENASBELEIFEND",
      company: "CONSECTETURXADIPISCINGOELIT",
    });
  };

  return (
    <Col
      className="private-projects__hidden-thumb"
      md={6}
      sm={12}
      onClick={handleKeyboardVisible}
    >
      <div
        className="private-projects__thumb-image"
        ref={ref}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <GatsbyImage 
          alt="PASSCORD REQUIRED" 
          image={getImage(hiddenImage)} 
        />
      </div>
      <div className="projects__name">
        <span className="text-wrapper">
          <span className={`text-mask ${hovered ? "unredacting" : "redacted"}`} />
          <span className="name__text-content">{displayedText.name}</span>
        </span>
      </div>
      <div className="projects__company">
        <span className="text-wrapper">
          <span className={`text-mask ${hovered ? "unredacting" : "redacted"}`} />
          <span className="company__text-content">{displayedText.company}</span>
        </span>
      </div>
    </Col>
  );
};

export default HiddenPrivateThumb;