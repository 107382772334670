import React from 'react';
import { motion, useIsPresent } from 'framer-motion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Seo from '../components/Seo';
import Layout from '../components/Layout';
import '../styles/pages/_contact.scss';

const Twitter = () => (
  <svg id="X" className="social--x" data-name="X" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.8 20">
    <path className="cls-1" d="M11.9,8.4L19.3,0h-1.8l-6.5,7.3L5.9,0H0l7.8,11.1L0,20h1.7l6.8-7.8,5.4,7.8h5.9M2.4,1.2h2.7l12.5,17.5h-2.7"/>
  </svg>
)

const Bluesky = () => (
  <svg id="Bluesky" className="social--icon" data-name="Bluesky" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.8 19.59">
    <path className="cls-1" d="M5.1,2.16c2.3,1.7,4.8,5.2,5.7,7.1.9-1.9,3.4-5.4,5.7-7.1,1.7-1.2,4.3-2.2,4.3.9s-.4,5.1-.6,5.9c-.7,2.6-3.3,3.2-5.6,2.8,4,.7,5.1,3,2.9,5.2-4.2,4.3-6.1-1.1-6.5-2.5,0-.3-.1-.4-.1-.3,0-.1,0,0-.1.3-.5,1.4-2.3,6.8-6.5,2.5-2.2-2.3-1.2-4.6,2.9-5.2-2.3.4-4.9-.3-5.6-2.8-.2-.7-.6-5.3-.6-5.9C1-.04,3.7.96,5.3,2.16h-.2Z"/>
  </svg>
);

const Instagram = () => (
  <svg id="Instagram" className="social--icon" data-name="Instagram" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
    <path className="cls-1" d="M6,1h10c2.8,0,5,2.2,5,5v10c0,2.8-2.2,5-5,5H6c-2.8,0-5-2.2-5-5V6C1,3.2,3.2,1,6,1Z"/>
    <path className="cls-1" d="M15,10.4c.3,2.2-1.2,4.2-3.4,4.5-2.2.3-4.2-1.2-4.5-3.4-.3-2.1,1.2-4.2,3.4-4.5.4-.1.8-.1,1.2,0,1.7.3,3,1.6,3.3,3.4Z"/>
    <line className="cls-1" x1="16.5" y1="5.5" x2="16.5" y2="5.5"/>
  </svg>
);

const LinkedIn = () => (
  <svg id="LinkedIn" className="social--icon" data-name="LinkedIn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 21">
    <path className="cls-1" d="M15,7c3.3,0,6,2.7,6,6v7h-4v-7c0-1.1-.9-2-2-2s-2,.9-2,2v7h-4v-7c0-3.3,2.7-6,6-6Z"/>
    <rect className="cls-1" x="1" y="8" width="4" height="12"/>
    <circle className="cls-1" cx="3" cy="3" r="2"/>
  </svg>
)

const Contact = () => {
  const isPresent = useIsPresent();

  return (
    <>
    <Seo title='Contact Me' />
    <Layout pageId='contact'>
      <Container>
        <Row className='contact'>
          <motion.div 
            className='col-md-6 col-sm-12'
            initial={{ opacity: 0, y: 25 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ 
              duration: 1, 
              ease: [0.61, 1, 0.88, 1] 
            }}
          >
            <h1 className='contact__title'><div>COME</div><div>AND</div><div>SAY HI</div></h1>
            <Row>
              <Col md={{ span: 8, offset: 4 }} sm={12}>
                <p className='contact__copy'>Feel free to reach out if you wanna collaborate with me, or simply have a chat</p>
              </Col>
            </Row>
            <Row>
              <Col sm xs={6}>
                <a href='https://x.com/PacManNR7' className="social--links" target='_blank' rel='noreferrer'>
                  <Twitter />
                  <p>X</p>
                </a>
              </Col>
              <Col sm xs={6}>
                <a href='https://bsky.app/profile/pacmannr7.bsky.social' className="social--links" target='_blank' rel='noreferrer'>
                  <Bluesky />
                  <p>BLUESKY</p>
                </a>
              </Col>
              <Col sm xs={6}>
                <a href='https://instagram.com/PacManNR7' className="social--links" target='_blank' rel='noreferrer'>
                  <Instagram />
                  <p>INSTAGRAM</p>
                </a>
              </Col>
              <Col sm xs={6}>
                <a href='https://www.linkedin.com/in/markpacis' className="social--links" target='_blank' rel='noreferrer'>
                  <LinkedIn />
                  <p>LINKEDIN</p>
                </a>
              </Col>
            </Row>
          </motion.div>
          <motion.div
            className='col-md-6 col-sm-12 email'
            initial={{ opacity: 0, y: 25 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ 
              duration: 1, 
              delay: 1.25, 
              ease: [0.61, 1, 0.88, 1] 
            }}
          >
            <a className="email" href='mailto:markpacis.creative@gmail.com'><h1>markpacis.creative@gmail.com</h1></a>
          </motion.div>
        </Row>
        <motion.div
          initial={{ scaleY: 1 }}
          animate={{ scaleY: 0, transition: { duration: 0.75, ease: [0.16, 1, 0.3, 1] } }}
          exit={{ scaleY: 1, transition: { duration: 0.75, ease: [0.7, 0, 0.84, 0] } }}
          style={{ originY: isPresent ? 0 : 1 }}
          className='screen-page-transition'
        />
      </Container>
    </Layout>
    </>
  )
}

export default Contact