import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import "../../styles/components/_keypad.scss";

const Keypad = ({ show, onPasscodeComplete, onKeyPress, onClose, input, status }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleKeyDown = (event) => {
    if (isDisabled) return;
    if (event.key === "Enter") {
      onPasscodeComplete();
    } else if (/^[0-9]$/.test(event.key)) {
      onKeyPress(event.key);
    } else if (event.key === "Backspace") {
      onKeyPress("backspace");
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isDisabled]);

  useEffect(() => {
    if (input.length === 6) {
      setIsDisabled(true);
      onPasscodeComplete();
    }
  }, [input, onPasscodeComplete]);

  useEffect(() => {
    if (status) {
      const timer = setTimeout(() => {
        setIsDisabled(false);
      }, 1000); // Feedback display duration
      return () => clearTimeout(timer);
    }
  }, [status]);

  return (
    <Modal show={show} onHide={onClose} centered>
      <h1 className="keypad__title">Enter Passcode</h1>
      <Modal.Body>
        <div className="keypad-container">
          <div className={`keypad-input ${status}`}>
            {[...Array(6)].map((_, i) => (
              <span key={i} className={`dot ${i < input.length ? "filled" : ""}`} />
            ))}
          </div>

          <div className={`keypad-grid ${isDisabled ? "disabled" : ""}`}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, "empty", 0, "empty"].map((key, index) =>
              key === "empty" ? (
                <div key={index} className="key empty" />
              ) : (
                <button
                  key={index}
                  className="key"
                  onClick={() => !isDisabled && onKeyPress(key.toString())}
                  disabled={isDisabled}
                >
                  {key}
                </button>
              )
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Keypad;