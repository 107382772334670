import React, { Component } from "react";
import { motion } from "framer-motion";
import Row from "react-bootstrap/Row";

import PublicProjectThumb from "./projects/PublicProjectThumb";
import PrivateProjectThumb from "./projects/PrivateProjectThumb";
import HiddenPrivateThumb from "./projects/PrivateHiddenThumb";
import Keypad from "./projects/Keypad";

class ProjectsGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      privateVisible: false,
      showKeypad: false,
      input: "",
    };

    this.handleKeyboardVisible = this.handleKeyboardVisible.bind(this);
    this.handleKeyboardClose = this.handleKeyboardClose.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleKeyboardVisible() {
    this.setState({ showKeypad: true });
  }

  handleKeyboardClose() {
    this.setState({
      showKeypad: false,
      input: "",
    });
  }

  handleKeyPress(value) {
    if (value === "backspace") {
      this.setState((prevState) => ({
        input: prevState.input.slice(0, -1),
      }));
    } else if (/^\d$/.test(value) && this.state.input.length < 6) {
      this.setState((prevState) => ({
        input: prevState.input + value,
      }));
    }
  }

  handleSubmit = () => {
    const correctPasscode = "121778";
  
    if (this.state.input === correctPasscode) {
      this.setState({
        privateVisible: true,
        showKeypad: false,
        input: "",
        status: "success",
      });
    } else {
      this.setState({ status: "failure" });
      setTimeout(() => {
        this.setState({ input: "", status: "" });
      }, 1250); // A quarter minus feedback duration
    }
  };

  render() {
    const { publicProjects, privateProjects } = this.props;

    return (
      <motion.section
        className="container"
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: [0.61, 1, 0.88, 1] }}
      >
        <Row className="projects__grid">
          {publicProjects.edges.map((document) => (
            <PublicProjectThumb
              key={document.node.uid}
              company={document.node.data.company.text}
              image={document.node.data.thumbnail}
              name={document.node.data.project_name.text}
              uid={document.node.uid}
            />
          ))}
        </Row>

        <Row className="private-projects__grid">
          <div className="private__header">
            <h4>PRIVATE PROJECTS</h4>
            <span className="line"></span>
          </div>

          {!this.state.privateVisible ? (
            <>
              {privateProjects.edges.map((document, i) => (
                <HiddenPrivateThumb
                  key={i}
                  hiddenImage={document.node.data.hidden_thumbnail}
                  handleKeyboardVisible={this.handleKeyboardVisible}
                />
              ))}

              {this.state.showKeypad && (
                <Keypad
                  show={this.state.showKeypad} // Controls modal visibility
                  onPasscodeComplete={this.handleSubmit} // Submit the passcode
                  onKeyPress={this.handleKeyPress} // Handle key presses
                  onClose={this.handleKeyboardClose} // Close the modal
                  input={this.state.input} // Current passcode input
                  status={this.state.status}
                />
              )}
            </>
          ) : (
            privateProjects.edges.map((document) => (
              <PrivateProjectThumb
                key={document.node.uid}
                company={document.node.data.company.text}
                image={document.node.data.thumbnail}
                name={document.node.data.project_name.text}
                uid={document.node.uid}
              />
            ))
          )}
        </Row>
      </motion.section>
    );
  }
}

export default ProjectsGrid;